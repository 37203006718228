<template>
  <div>
    <header class="header">
      <router-link to="/external/privacy-portal">
        <img
          class="cursor-pointer"
          src="@/assets/logo-header.webp"
          height="40px"
          alt="Logo da Cassems"
        />
      </router-link>

      <nav class="lg-navigation">
        <ul>
          <li>
            <router-link to="/external/privacy-portal"> Início </router-link>
          </li>
          <li @click.prevent="scrollToPublications">
            <router-link to="/external/privacy-portal">
              Publicações
            </router-link>
          </li>
          <li>
            <a href="https://www.lgpd.ms.gov.br/" target="_blank">LGPD</a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=556733141010&text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20link%20de%20WhatsApp%20do%20Site%20Cassems"
              target="_blank"
              >Contato</a
            >
          </li>
        </ul>
      </nav>
      <div class="menu-icon">
        <v-icon size="35px" color="secondary" @click="toggleMenu">
          mdi-menu
        </v-icon>
      </div>
    </header>

    <div
      :class="[isMenuOpen ? 'side-menu' : 'd-none']"
      @click.self="toggleMenu"
    >
      <div class="pa-2 text-end">
        <v-icon color="secondary" size="26" @click="toggleMenu">
          mdi-close
        </v-icon>
      </div>
      <nav>
        <ul>
          <li>
            <router-link to="/external/privacy-portal"> Início </router-link>
          </li>
          <li>
            <router-link to="/external/privacy-portal">
              Publicações
            </router-link>
          </li>
          <li>
            <a href="https://www.lgpd.ms.gov.br/" target="_blank">LGPD</a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=556733141010&text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20link%20de%20WhatsApp%20do%20Site%20Cassems"
              target="_blank"
              >Contato</a
            >
          </li>
        </ul>
      </nav>
    </div>

    <div :class="[isMenuOpen ? 'overlay' : 'd-none']" @click="toggleMenu" />
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    scrollToPublications() {
      this.$emit("scrollToPublications");
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.lg-navigation ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  position: relative;
  transition: color 0.3s ease;
}

li a::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #003ec5;
  transition: width 0.3s ease;
}

li a:hover {
  color: #003ec5;
}

li a:hover::after {
  width: 100%;
}

.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease;
  z-index: 1000;
}

.side-menu nav ul {
  list-style: none;
  padding: 5px 20px;
}

.side-menu nav ul li {
  margin-bottom: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
}

@media (max-width: 768px) {
  .lg-navigation {
    display: none;
  }

  .header img {
    height: 30px;
  }
}

@media (min-width: 769px) {
  .menu-icon {
    display: none;
  }
}
</style>
