<template>
  <v-container fluid class="pa-0">
    <img
      src="@/assets/banner_privacy_portal.webp"
      class="banner"
      width="100%"
      alt="Imagem do Hospital da Cassems em Campo Grande, Mato Grosso do Sul"
    />

    <v-row>
      <v-col cols="10" class="mx-auto">
        <h1 class="my-3">Portal da Privacidade</h1>
        <p>
          O Portal da Privacidade é uma plataforma centralizada que oferece
          informações e recursos sobre privacidade e proteção de dados. Ele
          serve como um canal de comunicação para esclarecer dúvidas, acessar
          documentos, artigos e vídeos educativos, além de atender solicitações
          relacionadas à Lei Geral de Proteção de Dados (LGPD). O portal
          facilita o acesso às políticas de privacidade da empresa, garantindo
          transparência e suporte aos direitos dos usuários.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-row class="container-blue">
        <v-col cols="10" class="mx-auto mb-3">
          <h2 class="my-3">Acesso Rápido</h2>
          <QuickAccess :items="quickAccess" />
        </v-col>
      </v-row>
    </v-row>

    <v-row>
      <v-col cols="12" ref="latestPublications">
        <v-row>
          <v-col cols="10" class="mx-auto">
            <h2 class="my-3">Últimas publicações</h2>
            <div>
              <LatestPrivacyNews />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider class="custom-divider"></v-divider>
  </v-container>
</template>

<script>
import QuickAccess from "./QuickAccess.vue";
import LatestPrivacyNews from "./LatestPrivacyNews.vue";

export default {
  name: "PrivacyPortalHome",
  components: { QuickAccess, LatestPrivacyNews },
  data() {
    return {
      quickAccess: [
        {
          id: 1,
          text: "Política de Segurança da Informação",
          link: "https://www.lgpd.ms.gov.br/wp-content/uploads/2022/03/POLITICA-DE-SEGURANCA-DA-INFORMACAO-DO-ESTADO-DE-MS.pdf",
        },
        {
          id: 2,
          text: "Sobre a Lei Geral de Proteção de Dados",
          link: "https://www.lgpd.ms.gov.br/sobre-a-lgpd/",
        },
        {
          id: 3,
          text: "Cartilha Lei Geral de Proteção de Dados",
          link: "https://www.lgpd.ms.gov.br/wp-content/uploads/2021/11/Cartilha_LGPD-com-links.pdf",
        },
        {
          id: 4,
          text: "Inventário de Dados Pessoais",
          link: "https://www.lgpd.ms.gov.br/inventario-de-dados-pessoais-2/",
        },
        {
          id: 5,
          text: "Artigos e Publicações",
        },
        {
          id: 6,
          text: "Canal de Atendimento",
        },
        {
          id: 7,
          text: "Vídeos Explicativos",
        },
        {
          id: 8,
          text: "Editais Disponíveis",
        },
      ],
    };
  },
};
</script>

<style scoped>
.banner {
  max-height: 60vh;
}

.container-blue {
  background-color: #173c94;
  color: white;
}

.custom-divider {
  background-color: #173c94;
  border-width: 1.5px;
}
</style>
