<template>
  <v-container fluid style="font-family: 'Montserrat', sans-serif">
    <v-row>
      <v-col cols="12" class="pa-0">
        <PrivacyPortalHeader @scrollToPublications="scrollToPublications" />
      </v-col>
      <v-col cols="12" class="pa-0">
        <PrivacyPortalHome ref="privacyPortalHome" />
      </v-col>
      <v-col cols="12" class="pa-0">
        <PrivacyPortalFooter />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrivacyPortalHeader from "@/components/PrivacyPortal/Header.vue";
import PrivacyPortalFooter from "@/components/PrivacyPortal/Footer.vue";
import PrivacyPortalHome from "@/components/PrivacyPortal/Home.vue";

export default {
  name: "PortalPrivacidadeExternal",
  components: {
    PrivacyPortalHeader: PrivacyPortalHeader,
    PrivacyPortalFooter: PrivacyPortalFooter,
    PrivacyPortalHome: PrivacyPortalHome,
  },
  methods: {
    scrollToPublications() {
      this.$nextTick(() => {
        const section = this.$refs.privacyPortalHome?.$refs.latestPublications;
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
};
</script>
