<template>
  <v-footer class="footer">
    <v-container>
      <v-row align="center" justify="space-between" class="footer-content">
        <v-col cols="12" md="3" class="social-icons text-center text-md-left">
          <a
            href="https://www.facebook.com/cassems"
            target="_blank"
            class="social-link"
          >
            <v-icon class="social-icon" color="blue"> mdi-facebook </v-icon>
          </a>
          <a
            href="https://www.youtube.com/@TVCASSEMS"
            target="_blank"
            class="social-link"
          >
            <v-icon class="social-icon" color="red"> mdi-youtube </v-icon>
          </a>
          <a
            href="https://www.linkedin.com/company/cassems"
            target="_blank"
            class="social-link"
          >
            <v-icon class="social-icon" color="#0A66C2"> mdi-linkedin </v-icon>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=556733141010&text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20atrav%C3%A9s%20do%20link%20de%20WhatsApp%20do%20Site%20Portal%20da%20Privacidade"
            target="_blank"
            class="social-link"
          >
            <v-icon class="social-icon" color="green"> mdi-whatsapp </v-icon>
          </a>
        </v-col>

        <v-col cols="12" md="6" class="text-center atendimento">
          <span>CENTRAL DE ATENDIMENTO | MS: </span>
          <v-icon small color="green"> mdi-whatsapp </v-icon>
          <span class="phone-number">67 3314-1002</span>
          <span class="red-text">24H</span>
          <span>| DOURADOS: </span>
          <span class="phone-number">67 3411-7530</span>
        </v-col>

        <v-col cols="12" md="3" class="text-center text-md-right">
          <span>Cassems 2025 © Todos direitos reservados</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "ComplianceFooter",
};
</script>

<style scoped>
.footer {
  background-color: white;
  border-top: 1px solid #ddd;
  padding: 10px 0;
  font-size: 14px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

.social-icons {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.social-icon {
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.2s;
}

.social-icon:hover {
  transform: scale(1.2);
}

.social-link {
  text-decoration: none;
}

.atendimento {
  font-weight: bold;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.phone-number {
  color: #173c93;
  font-weight: bold;
}

.red-text {
  color: red;
  font-weight: bold;
}
</style>
