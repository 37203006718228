<template>
  <v-container>
    <div
      v-if="privacyNews && privacyNews.length === 0"
      class="my-8 text-center"
    >
      <h2>Nenhuma publicação encontrada.</h2>
    </div>
    <v-row v-else>
      <v-col
        v-for="news in paginatedPrivacyNews"
        :key="news.id"
        cols="12"
        md="4"
        sm="6"
      >
        <v-card
          hover
          height="500"
          elevation="10"
          class="mx-auto d-flex flex-column justify-space-between"
          color="white lighten-5"
          style="
            border-radius: 15px;
            transition: transform 0.3s;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          "
        >
          <v-img
            class="white--text align-end"
            height="200px"
            :src="news.featuredImage"
            style="border-top-left-radius: 15px; border-top-right-radius: 15px"
          />
          <v-card-subtitle
            class="text-uppercase pt-5 px-6 pb-0"
            style="color: #616161"
          >
            {{ news.createdAt | dateFormatted }}
          </v-card-subtitle>
          <v-card-text class="py-0 px-2 text-card">
            <v-card-title class="text-truncate" style="font-weight: 600">
              {{ news.headline }}
            </v-card-title>
            <v-card-subtitle class="text-truncate" style="color: #757575">
              {{ news.caption }}
            </v-card-subtitle>
          </v-card-text>
          <v-card-actions>
            <v-btn
              outlined
              color="secondary"
              :to="`${'/external/read-privacy-news/'}${news.slug}`"
              style="border-radius: 20px; padding: 5px 20px; margin: 20px"
            >
              Ver mais
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-col cols="12" class="text-center">
      <v-pagination v-model="currentPage" :length="pageCount" color="#173c93" />
    </v-col>
  </v-container>
</template>

<script>
import { findActiveExternalLgpd } from "@/services/lgpd-service";

export default {
  name: "LatestPrivacyNews",
  data() {
    return {
      privacyNews: [],
      currentPage: 1,
      itemsPerPage: 4,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.privacyNews.length / this.itemsPerPage);
    },
    paginatedPrivacyNews() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.privacyNews.slice(start, end);
    },
  },
  async created() {
    this.privacyNews = await findActiveExternalLgpd();
  },
};
</script>
<style scoped></style>
