<template>
  <v-container>
    <v-row justify="center">
      <v-col v-for="item in items" :key="item.id" cols="12" md="3" sm="5">
        <v-card
          hover
          class="custom-card"
          link
          :href="item.link"
          target="_blank"
        >
          <v-card-title class="card-title">{{ item.text }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "QuickAccess",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #0e2c7e;
  text-align: start;
  white-space: normal;
  word-break: keep-all;
}
.custom-card {
  background-color: white;
  border-radius: 16px;
  padding: 15px;
  width: 300px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
